import React from "react";
import H from "./H";

export default function H2({
    children
}) {
    return (
        <H
            tagName="h2"
            level={2}
        >
            {children}
        </H>
    )
}