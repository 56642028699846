import React from "react";


export default function H({
    children,
    headingClassName = "mt-6 text-3xl leading-8 font-extrabold tracking-tight text-theme_blue pb-2 border-b-2 border-theme_blue border-x-none border-t-none",
    level = 1,
    containerClassName = "max-w-prose lg:max-w-none w-full",
}) {
    const Tag = `h${level}`;
    return (
        <div className={containerClassName}>
            <Tag className={headingClassName}>
                {children}
            </Tag>
        </div>
    )
}