import React from "react";
import { getIcon } from "../StatusIcons";

const StatusEnum = {
    0: "VA in Bearbeitung",
    1: "VA erstellt",
    2: "VA unterzeichnet",
    3: "VA abgeschlossen",
}


export default function Status({
    status
}) {
    return (
        <div className="flex flex-row gap-2 items-center">
            {
                status === 0 &&
                [...Array(3).keys()].map((s, index) => {
                    const statusText = StatusEnum[index]
                    return (
                        <div>
                            {/* {index <= status ? getIcon(true, statusText) : getIcon(false, statusText)} */}
                            {getIcon(false, statusText)}

                        </div>
                    )
                })
            }

{
                status > 0 &&
                [...Array(3).keys()].map((s, index) => {
                    const statusText = StatusEnum[index]
                    return (
                        <div>
                            {index + 1 <= status ? getIcon(true, statusText) : getIcon(false, statusText)}
                        </div>
                    )
                })
            }

            {StatusEnum[status]}

        </div>
    )
}