import React from 'react';

import TopBar from '../components/TopBar';
import Header from '../components/Header';
import Base from '../layout/base';
import { getIcon } from '../components/StatusIcons';
import BaseTable from '../components/Table/BaseTable';
import Status from '../components/Content/Status';
import H2 from '../components/Content/H2';

const projekte2 = [
    {
        eingang: "15.06.2022",
        name: "Mustermann",
        vorname: "Max",
        standort: "Luzern",
        plz: 6003,
        zuteilung: "",
        status: 0,
    },
    {
        eingang: "14.06.2022",
        name: "Meier",
        vorname: "Mathias",
        standort: "Horw",
        plz: 6048,
        zuteilung: "",
        status: 0,
    },

]


const projekte_3 = [
    {
        eingang: "10.06.2022",
        name: "Huber",
        vorname: "Maria",
        standort: "Zürich",
        plz: 8000,
        zuteilung: "Lothar Arnold",
        status: 1,

    },
    {
        eingang: "08.06.2022",
        name: "Steffen",
        vorname: "Simone",
        standort: "Kriens",
        plz: 6010,
        zuteilung: "Lothar Arnold",
        status: 1,
    },
    {
        eingang: "05.06.2022",
        name: "Meinecke",
        vorname: "Tim",
        standort: "Horw",
        plz: 6048,
        zuteilung: "Lothar Arnold",
        status: 2,
    },
    {
        eingang: "03.06.2022",
        name: "Rast",
        vorname: "Verena",
        standort: "Luzern",
        plz: 6003,
        zuteilung: "Lothar Arnold",
        status: 3,
    },
    {
        eingang: "02.06.2022",
        name: "Heim",
        vorname: "Lisa",
        standort: "Zürich",
        plz: 8003,
        zuteilung: "Lothar Arnold",
        status: 3,
    },
]

const headers = [
    {
        label: "Eingang",
        className: "py-1.5 pl-4 pr-3 text-left text-md font-normal text-gray-900 sm:pl-3",
    }, {
        label: "Name",
        className: "py-1.5 px-3 text-left text-md font-normal text-gray-900",
    },
    {
        label: "Vorname",
        className: "py-1.5 px-3 text-left text-md font-normal text-gray-900",
    },
    {
        label: "PLZ",
        className: "py-1.5 px-3 text-left text-md font-normal text-gray-900",
    },
    {
        label: "Ort",
        className: "py-1.5 px-3 text-left text-md font-normal text-gray-900",
    },
    {
        label: "Zuteilung",
        className: "py-1.5 px-3 text-left text-md font-normal text-gray-900",
    },
    {
        label: "Status",
        className: "py-1.5 px-3 text-left text-md font-normal text-gray-900",
    },
]

function Projekte() {

    const projekteRows = projekte2.map((p, index) => {
        return {
            cols: [
                {
                    className: "whitespace-nowrap py-4 pl-4 pr-3 text-md font-bold text-gray-900 sm:pl-3 w-[10%]",
                    children: (
                        <span>
                            {p.eingang}
                        </span>
                    )
                },
                {
                    className: "whitespace-nowrap py-4 px-3 text-md text-gray-500 w-[15%]",
                    children: (
                        <span>
                            {p.name}
                        </span>
                    )
                },
                {
                    className: "whitespace-nowrap py-4 px-3 text-md text-gray-500  w-[15%]",
                    children: (
                        <span>
                            {p.vorname}
                        </span>
                    )
                },
                {
                    className: "whitespace-nowrap py-4 px-3 text-md text-gray-500  w-[15%]",
                    children: (
                        <span>
                            {p.plz}
                        </span>
                    )
                },
                {
                    className: "whitespace-nowrap py-4 px-3 text-md text-gray-500  w-[15%]",
                    children: (
                        <span>
                            {p.standort}
                        </span>
                    )
                },
                {
                    className: "whitespace-nowrap py-4 px-3 text-md text-gray-500  w-[35%]",
                    children: (
                        <span>
                            {p.zuteilung}
                        </span>
                    )
                },
                {
                    className: "whitespace-nowrap py-4 px-3 text-md text-gray-500 w-[10%]",
                    children: (<Status status={p.status} />)
                }
            ]
        }
    });


    const projekteRows2 = projekte_3.map((p, index) => {
        return {
            cols: [
                {
                    className: "whitespace-nowrap py-4 pl-4 pr-3 text-md font-bold text-gray-900 sm:pl-3 w-[10%]",
                    children: (
                        <span>
                            {p.eingang}
                        </span>
                    )
                },
                {
                    className: "whitespace-nowrap py-4 px-3 text-md text-gray-500 w-[15%]",
                    children: (
                        <span>
                            {p.name}
                        </span>
                    )
                },
                {
                    className: "whitespace-nowrap py-4 px-3 text-md text-gray-500  w-[15%]",
                    children: (
                        <span>
                            {p.vorname}
                        </span>
                    )
                },
                {
                    className: "whitespace-nowrap py-4 px-3 text-md text-gray-500  w-[15%]",
                    children: (
                        <span>
                            {p.plz}
                        </span>
                    )
                },
                {
                    className: "whitespace-nowrap py-4 px-3 text-md text-gray-500  w-[15%]",
                    children: (
                        <span>
                            {p.standort}
                        </span>
                    )
                },
                {
                    className: "whitespace-nowrap py-4 px-3 text-md text-gray-500  w-[35%]",
                    children: (
                        <span>
                            {p.zuteilung}
                        </span>
                    )
                },
                {
                    className: "whitespace-nowrap py-4 px-3 text-md text-gray-500 w-[10%]",
                    children: (<Status status={p.status} />)
                }
            ]
        }
    });

    return (
        <Base>
            <div className="App">
                <TopBar />
                <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8 bg-white overflow-hidden text-left">
                    <div className="relative z-10 mb-8 md:mb-2 md:px-6"></div>
                    <Header title="Neue Versicherungsanträge" />

                    <div className="mt-8 flex flex-col">
                        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                <BaseTable
                                    filter={true}
                                    data={projekteRows}
                                    headers={headers}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="max-w-7xl mx-auto py-16 bg-white overflow-hidden text-left">



                        <div className='mt-24'>
                            <H2>
                                Pendente Versicherungsanträge
                            </H2>
                            <div className="mt-8 flex flex-col">
                                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                                        <BaseTable
                                            filter={true}
                                            data={projekteRows2}
                                            headers={headers}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </Base >
    );
}

export default Projekte;
